import React, { useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'

import { clsNames } from 'unno-comutils'
import Dropdown, { DropdownItem } from 'unno-comutils/Dropdown'
import { Button, Icon } from 'unno-comutils/ui'

import { MenuContent } from '../service/menu'
import { ITEM_CONTACTs } from '../service/variable'

export function Layout (props: any) {
    const { components } = props
    return <LayoutWeb components={components} children={props.children}/>
}

function LayoutWeb (props: { components: any, children: any }) {
    const { components, children } = props

    /*<div className="layout-top-menu">
            <div className={'mr-auto'}><Link href={'/home'}><Icon name={'home'} button/></Link></div>
            {MenuMain.map((menu, index) => <Link key={'m-' + menu.key} href={menu.url}><a className="_item">{menu.name}</a></Link>)}
        </div>
        <Image name={'header_photo'} className="layout-header" data={components?.find((d: any) => d.name === 'header_photo') || {}}/>
        */
    return <div className="layout">{children}</div>
}

const HEAD_ITEMs = [
    { page: 'news', name: 'ข่าวสารและโปรโมชั่น' },
    { page: 'blog', name: 'บทความที่น่าสนใจ' },
    { page: 'faq', name: 'คำถามที่พบบ่อย' },
    { page: 'contact_us', name: 'ติดต่อเรา' }
]

const HEAD_MOBLIE_CONTACTs = [
    { id: 'contact_us', name: 'ติดต่อเรา' },
    { id: 'faq', name: 'คำถามที่พบบ่อย' },
    { id: 'privacy', name: 'นโยบายความเป็นส่วนตัว' },
    { id: 'privacy_cookie', name: 'นโยบายส่วนบุคคลเกี่ยวกับ Cookie' },
    { id: 'tos', name: 'ข้อกำหนดของบริการ' },
]

const LANGs = [
    { id: 'th', name: 'ภาษาไทย', icon: 'thailand 1.png' },
    { id: 'en', name: 'English', icon: 'united-states-of-america 1.png' },
]

export function Language (props: { right?: boolean, lang: any, onChange: any }) {
    const { right, lang, onChange } = props

    return <Dropdown right={right != undefined ? right : true} button={
        <span className={'_i18n flex items-center'}>
             <img style={{ width: '20px', height: '20px' }} src={'/assets/icon/' + lang.icon} alt={lang.name} className={'mr-3'}/>
            {lang.name}
            <Icon name={'chevron-down ml-2'}/>
        </span>}>
        {LANGs.map((d: any) => {
            return <DropdownItem key={'item_' + d.id} label={d.name} onClick={() => onChange(d)}/>
        })}
    </Dropdown>

}

export function Header () {
    const { pathname, push } = useRouter()

    const [lang, setLang] = useState<any>(LANGs[0])
    const [moblieMenuMini, setMoblieMenuMini] = useState(false)

    const mobileMenu = useMemo(() => {
        if (['home'].some((s: any) => pathname.includes(s))) return 0
        if (['contact_us', 'faq', 'privacy', 'privacy_cookie', 'tos'].some((s: any) => pathname.includes(s))) return 1
        if (['insurance_interest', 'insurance_summary'].some((s: any) => pathname.includes(s))) return 2
        if (['insurance_search'].some((s: any) => pathname.includes(s))) return 3
        return null
    }, [pathname])

    return <div className={'layout-header'}>
        <div className={clsNames('layout-header-mobile', mobileMenu === 0 && '-home')}>
            <div className={'_head-top'}>
                {(() => {
                    if (mobileMenu === 0) return <>
                        {moblieMenuMini && <div className={'_menu-float'}>
                            <div className={'_form-top'}>
                                <Icon name={'times-circle blue'} lg solid button onClick={() => setMoblieMenuMini(false)}/>
                                <Link href="/home" className="_logo"><img src={'/static/logo-h.png'} alt="logo"/></Link>
                            </div>
                            <div className={'_form-list'}>
                                {HEAD_MOBLIE_CONTACTs.map((d: any) => {
                                    return <div key={d.id} className={'_item'} onClick={() => push('/' + d.id)}>{d.name}</div>
                                })}
                            </div>
                        </div>}

                        <div className={'_menu-home'}>
                            <Button className={'_menu-bar'} icon={'bars'} onClick={() => setMoblieMenuMini(true)}/>
                        </div>
                    </>

                    if (mobileMenu === 1)
                        return <>
                            <div className={'_menu'}>
                                {/*<Button className={'_menu-bar'} icon={'bars'}/>*/}
                                <Link href="/home" className="_logo"><img src={'/static/logo-h.png'} alt="logo"/></Link>

                                <Link className={'_button'} href={'/insurance_interest'}>
                                    <button><Icon name={'info-square'} className={'mr-2'} solid/>สนใจสมัครประกัน</button>
                                </Link>
                            </div>

                            <select className={'_select-contact'} onChange={(d) => push('/' + d.target.value)}>
                                {HEAD_MOBLIE_CONTACTs.map((d: any) => <option key={d.id} selected={pathname.includes(d.id)} value={d.id}>{d.name}</option>)}
                            </select>
                        </>

                    return <>
                        <div className={'_menu'}>
                            <Link href="/home" className="_logo"><img src={'/static/logo-h.png'} alt="logo"/></Link>
                            {mobileMenu === 3 && <div>ตัวกรอง</div>}
                        </div>
                    </>
                })()}
            </div>

            <div className="_head-menu">
                {HEAD_ITEMs.map((o: any) => <Link key={'m_' + o.page} href={'/' + o.page} className={clsNames('_item', pathname === '/' + o.page && '-active')}>{o.name}</Link>)}
            </div>
        </div>

        <div className={'layout-header-top'}>
            <Link href="/home" className="_logo"><img src={'/static/logo-h.png'} alt="logo"/></Link>
            <div className={'_item'}>
                <Icon name={'calendar-alt red'} solid className={'_icon'}/>
                <span className={'_text'}>จันทร์ - เสาร์ 8.30 - 17.30 น.</span>
            </div>
            <div className={'_item -border'}>
                <Icon name={'phone-alt blue'} solid className={'_icon'}/>
                <a href={'tel:020287888'} target="_blank" className={'_text -link'}>ติดต่อ 02-028-7888</a>
            </div>
            <div className={'_item -border green'}>
                <Icon name={'line'} brand className={'_icon'}/>
                <a href={'https://lin.ee/7EltnAK'} target="_blank" className={'_text -link'}>@obicare</a>
            </div>
        </div>

        <div className={'layout-header-menu'}>
            {HEAD_ITEMs.map((o: any) => <Link key={'m_' + o.page} href={'/' + o.page} className={clsNames('_item', pathname === '/' + o.page && '-active')}>{o.name}</Link>)}
        </div>

    </div>
}

export function Body (props: { children: any, fill?: boolean, flex?: boolean, flexFull?: boolean, noGrow?: boolean, className?: string, classContainer?: string }) {
    return <div className={clsNames('layout-body', props.flexFull && '-flex_full', props.noGrow && '-no_grow', props.className)}>{props.fill
        ? props.children
        : <div className={clsNames('_container', props.flex && '-flex', props.classContainer)}>{props.children}</div>}</div>
}

export function BodyContent (props: { children: any, className?: string }) {
    return <div className={clsNames('layout-body-content', props.className)}>{props.children}</div>
}

export function BodyHeader (props: { children: any, className?: string }) {
    return <div className={clsNames('layout-body-header', props.className)}>{props.children}</div>
}

const ITEM_VERIFYs = [
    { key: 'dbd', name: 'ใบอนุญาตจากกรมพัฒนาธุรกิจการค้า', number: '0165562000791' },
    { key: 'oic', name: 'ใบอนุญาตนายหน้าประกันภัยวินาศภัย', number: 'ว00009/2562' },
    { key: 'tgia', name: 'เลขที่สมาชิกสมาคมประกันวินาศภัย', number: '11/2564-179' }
]

export function Footer () {
    return <div className={'layout-footer'}>
        <div className={'_container'}>
            <div className={'_box-logo'}>
                <img src="/static/logo-h.svg" alt="logo-footer" className="_img"/>
                <div className={'_text'}>บริษัท Online Broker Insurance ( ออนไลน์ โบรกเกอร์ อินชัวร์แลนด์ )</div>
                <div className={'_text -sm'}>456/19-21 ถนนนารายณ์มหาราช ตำบลทะเลชุบศร อำเภอเมือง จังหวัดลพบุรี,</div>
                <div className={'_text'}>Lop Buri, Thailand, Lop Buri</div>
            </div>

            <div className={'_box-verify'}>
                <div className={'_head'}>มาตฐานการรับรอง</div>
                {ITEM_VERIFYs.map((o: any) => <div key={'v_' + o.key} className={'_item'}>
                    <img alt={o.key} src={'/static/verify/' + o.key + '.png'} className="_img"/>
                    <span className="_name">{o.name}</span>
                    <span className="_number">{o.number}</span>
                </div>)}
            </div>

            <div className={'_box-contact'}>
                <div className={'_head'}>ติดต่อเรา</div>
                <div className="_items">
                    {ITEM_CONTACTs.map((o: any) => <a key={'o_' + o.key} className={'_item'} href={o.url} target="_blank">
                        <Icon name={o.icon} solid={!o.brand} brand={o.brand} className={'_icon'}/>
                        <span className="_name">{o.name}</span>
                    </a>)}
                </div>
            </div>
        </div>
    </div>
}

export function FooterLink () {
    return <div className={'layout-footer-link'}>
        <Link href={'/privacy'}>นโยบายความเป็นส่วนตัว</Link>
        <Link href={'/privacy_cookie'}>นโยบายส่วนบุคคลเกี่ยวกับ Cookie</Link>
    </div>
}

export function MenuSide () {
    const { pathname } = useRouter()

    return <div className={'menu-side'}>
        {MenuContent.map((o: any) => <Link key={'m_' + o.page} href={'/' + o.page} className={clsNames('_item', pathname === '/' + o.page && '-active')}>
            <span className="_text">{o.name}</span>
            <Icon name={'chevron-right'} className="_icon"/>
        </Link>)}
    </div>
}
