export const ITEM_CONTACTs = [
    { key: 'tel', icon: 'tablet-android-alt blue', brand: false, name: '02-028-7888', url: 'tel:020287888' },
    { key: 'fb', icon: 'facebook-messenger blue', brand: true, name: 'Messenger', url: 'https://m.me/OBIbrokerTH' },
    { key: 'line', icon: 'line green', brand: true, name: '@obicare', url: 'https://lin.ee/7EltnAK' },
    { key: 'web', icon: 'globe-asia purple', brand: false, name: 'www.obi.co.th', url: 'https://www.obi.co.th' },
]

export const ITEM_INSURANCE_TYPEs = [
    { id: 1003, name: 'ป.1' },
    { id: 1005, name: 'ป.2+' },
    { id: 1004, name: 'ป.2' },
    { id: 1007, name: 'ป.3+' },
    { id: 1006, name: 'ป.3' },
]

export const PROVINCEs = [
    { id: 10, name: 'กรุงเทพมหานคร' },
    { id: 81, name: 'กระบี่' },
    { id: 71, name: 'กาญจนบุรี' },
    { id: 46, name: 'กาฬสินธุ์' },
    { id: 62, name: 'กำแพงเพชร' },
    { id: 40, name: 'ขอนแก่น' },
    { id: 22, name: 'จันทบุรี' },
    { id: 24, name: 'ฉะเชิงเทรา' },
    { id: 20, name: 'ชลบุรี' },
    { id: 18, name: 'ชัยนาท' },
    { id: 36, name: 'ชัยภูมิ' },
    { id: 86, name: 'ชุมพร' },
    { id: 50, name: 'เชียงใหม่' },
    { id: 57, name: 'เชียงราย' },
    { id: 92, name: 'ตรัง' },
    { id: 23, name: 'ตราด' },
    { id: 63, name: 'ตาก' },
    { id: 26, name: 'นครนายก' },
    { id: 73, name: 'นครปฐม' },
    { id: 48, name: 'นครพนม' },
    { id: 30, name: 'นครราชสีมา' },
    { id: 80, name: 'นครศรีธรรมราช' },
    { id: 60, name: 'นครสวรรค์' },
    { id: 12, name: 'นนทบุรี' },
    { id: 96, name: 'นราธิวาส' },
    { id: 55, name: 'น่าน' },
    { id: 31, name: 'บุรีรัมย์' },
    { id: 97, name: 'บึงกาฬ' },
    { id: 13, name: 'ปทุมธานี' },
    { id: 77, name: 'ประจวบคีรีขันธ์' },
    { id: 25, name: 'ปราจีนบุรี' },
    { id: 94, name: 'ปัตตานี' },
    { id: 14, name: 'พระนครศรีอยุธยา' },
    { id: 56, name: 'พะเยา' },
    { id: 82, name: 'พังงา' },
    { id: 93, name: 'พัทลุง' },
    { id: 66, name: 'พิจิตร' },
    { id: 65, name: 'พิษณุโลก' },
    { id: 76, name: 'เพชรบุรี' },
    { id: 67, name: 'เพชรบูรณ์' },
    { id: 54, name: 'แพร่' },
    { id: 83, name: 'ภูเก็ต' },
    { id: 44, name: 'มหาสารคาม' },
    { id: 49, name: 'มุกดาหาร' },
    { id: 58, name: 'แม่ฮ่องสอน' },
    { id: 35, name: 'ยโสธร' },
    { id: 95, name: 'ยะลา' },
    { id: 45, name: 'ร้อยเอ็ด' },
    { id: 85, name: 'ระนอง' },
    { id: 21, name: 'ระยอง' },
    { id: 70, name: 'ราชบุรี' },
    { id: 16, name: 'ลพบุรี' },
    { id: 52, name: 'ลำปาง' },
    { id: 51, name: 'ลำพูน' },
    { id: 42, name: 'เลย' },
    { id: 33, name: 'ศรีสะเกษ' },
    { id: 47, name: 'สกลนคร' },
    { id: 90, name: 'สงขลา' },
    { id: 91, name: 'สตูล' },
    { id: 11, name: 'สมุทรปราการ' },
    { id: 75, name: 'สมุทรสงคราม' },
    { id: 74, name: 'สมุทรสาคร' },
    { id: 27, name: 'สระแก้ว' },
    { id: 19, name: 'สระบุรี' },
    { id: 17, name: 'สิงห์บุรี' },
    { id: 64, name: 'สุโขทัย' },
    { id: 72, name: 'สุพรรณบุรี' },
    { id: 84, name: 'สุราษฎร์ธานี' },
    { id: 32, name: 'สุรินทร์' },
    { id: 43, name: 'หนองคาย' },
    { id: 39, name: 'หนองบัวลำภู' },
    { id: 15, name: 'อ่างทอง' },
    { id: 37, name: 'อำนาจเจริญ' },
    { id: 41, name: 'อุดรธานี' },
    { id: 53, name: 'อุตรดิตถ์' },
    { id: 61, name: 'อุทัยธานี' },
    { id: 34, name: 'อุบลราชธานี' }
]
