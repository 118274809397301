export const MenuMain = [
    { key: 'insurance', name: 'อยากซื้อประกันภัย', url: '/insurance' },
    { key: 'general', name: 'เรื่องทั่วไป', url: '/general' },
    { key: 'line', name: 'ไลน์', url: '/line' },
    { key: 'payment', name: 'ช่องทางการชำระเงิน', url: '/payment' },
    { key: 'promotion', name: 'โปรโมชั่น', url: '/promotion' },
    { key: 'article', name: 'บทความ', url: '/article' },
    { key: 'careers', name: 'ตำแหน่งงานว่าง', url: '/careers' },
    { key: 'contact', name: 'ติดต่อเรา', url: '/contact' },
    { key: 'visionMission', name: 'วิสัยทัศน์และพันธกิจ', url: '/visionMission' },
    /*
    { key: 'home2', name: 'บทความ', url: '/home', icon: 'home blue' },
    { key: 'home3', name: 'สาขาใกล้บ้าน', url: '/home', icon: 'home blue' },
    { key: 'home4', name: 'ติดต่อเรา', url: '/home', icon: 'home blue' },
    { key: 'home5', name: 'สมัครงาน', url: '/home', icon: 'home blue' },
    */
]

export const MenuConsole = [
    { key: 'component', name: 'Components', url: '/console/component', icon: 'atom' }
]

export const MenuContent = [
    { page: 'contact_us', name: 'ติดต่อเรา' },
    { page: 'faq', name: 'คำถามที่พบบ่อย' },
    { page: 'privacy', name: 'นโยบายความเป็นส่วนตัว' },
    { page: 'privacy_cookie', name: 'นโยบายส่วนบุคคลเกี่ยวกับ cookie ' },
    { page: 'tos', name: 'ข้อกำหนดของบริการ ' },
]